<template>
     <v-card
        elevation="10"
        class="mx-auto"
    >
        <v-toolbar
            dark
            cards                
            flat
            outlined
            color="primary"
        >
            <v-card-title class="text-h6 font-weight-regular">
            {{cmpOptions.title}}
            </v-card-title>


            <v-spacer> </v-spacer>
  
            <v-btn 
                @click= "close($event)"
                icon>
                <v-icon>mdi-close-thick</v-icon>
            </v-btn>
 
        </v-toolbar>
        <v-divider></v-divider>
        <v-container>
                <v-form
                    ref="form"
                    v-model="form"
                    @submit.prevent="submit"
                    class="pa-4 pt-6"
                >
                    <v-alert type="error" v-if="errMessage != ''">
                    {{errMessage}}
                    </v-alert>
                        <v-text-field
                            ref="name"
                            v-model="data.name.value"
                            :rules="data.name.rules"
                            :counter="data.name.counter"
                            :label="data.name.caption"
                            required
                            style="min-height: 96px">
                            @keyup.enter="onClickApply"
                        ></v-text-field>
                        <v-select
                            ref="device_type_id"
                            v-model="data.device_type_id.value"
                            :items="device_types"
                            item-text="caption"
                            item-value="id"
                            :label="data.device_type_id.caption"
                            style="min-height: 96px">
                        ></v-select>    
                        <v-text-field
                            ref="type"
                            v-model="data.type.value"
                            :rules="data.type.rules"
                            :counter="data.type.counter"
                            :label="data.type.caption"
                            required
                            style="min-height: 96px">
                            @keyup.enter="onClickApply"
                        ></v-text-field> 
                        <v-text-field
                            ref="manufacturer"
                            v-model="data.manufacturer.value"
                            :rules="data.manufacturer.rules"
                            :counter="data.manufacturer.counter"
                            :label="data.manufacturer.caption"
                            required
                            style="min-height: 96px">
                            @keyup.enter="onClickApply"
                        ></v-text-field>          
                        <v-menu
                            v-model="dataTimePicker"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            style="min-height: 96px">
                        >
                            <template class="pr-n12" v-slot:activator="{ on, attrs }">
                                <v-text-field
                                prepend-icon="mdi-calendar"
                                ref="date_of_construction"
                                v-model="data.date_of_construction.value"
                                :rules="data.date_of_construction.rules"
                                
                                v-bind="attrs"
                                v-on="on"
                                :label="data.date_of_construction.caption"
                                required
                                @change="onChange($event, 'date_of_construction')"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="datePickerValue"
                                @input="onChange($event, 'date_picker')" 
                                required
                            ></v-date-picker>
                        </v-menu>   
                        <v-text-field
                            ref="serialnumber"
                            v-model="data.serialnumber.value"
                            :rules="data.serialnumber.rules"
                            :counter="data.serialnumber.counter"
                            :label="data.serialnumber.caption"
                            required
                            @keyup.enter="onClickApply"
                            style="min-height: 96px">
                        ></v-text-field>  

                    <v-divider></v-divider>
                    <v-card-actions>
                    <v-btn
                        
                        outlined
                        @click="init"
                    >
                        Reset
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="!form"
                        :loading="isLoading"
                        dark
                        color="primary"
                        depressed
                        type="submit"              
                    >
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-container>
    </v-card>
</template>

<script>
 
export default {
    props:{
        item: {
            required: false,
            type: Object,
            default: () => undefined
        },
        device_types:{
            required: true,
            type: Array,
            default: () => undefined
        },
        options:{
            required: false,
            type: Object
        },
        isVisible:{                
            required: true,
            default: false,
            type: Boolean
        }
    },

    data() {
        return {
            defaultOptions:{
                title: 'Title',
                isEdit: true
            },
            defaultItem: {
               
                device_type_id: null,
                device_type_name: "",
                device_type_caption: "",
                name: "",
                type: "",
                manufacturer: "",
                date_of_construction: "",
                serialnumber: "",
                token:""
 
            },
            editItem: {

                device_type_id: null,
                device_type_name: "",
                device_type_caption: "",
                name: "",
                type: "",
                manufacturer: "",
                date_of_construction: "",
                serialnumber: "",
                token:""

            },
            
            datePickerValue: '2000-01-01',
            
            dataTimePicker: false,
            data:{
                name:{
                    value:'',
                    caption:'Name',
                    rules:[
                        v => !! v || 'Eingabe ist erforderlich',
                        v => (v && v.length <= 100) || 'max. 100 Zeichen erlaubt',
                    ],
                    counter: 100,
                },
                device_type_id:{
                    value: '',
                    caption:'Smart Device Typ',
                    
                    selectedId: 0,
                    selectedItem:{ 
                        id: 1,
                        name:'',
                        description:'',
                    },
                    rules:[],
                    counter: 100,
                },
                type:{
                    value:'',
                    caption:'Typ',
                    rules:[
                        v => !!v || 'Eingabe ist erforderlich',
                        v => (v && v.length <= 50) || 'max. 50 Zeichen erlaubt',
                    ],
                    counter: 50,
                },
                serialnumber:{
                    value:'',
                    caption:'Seriennummer',
                    rules:[
                        v => !!v || 'Eingabe ist erforderlich',
                        v => (v && v.length <= 50) || 'max. 50 Zeichen erlaubt',
                    ],
                    counter: 50,
                },
                manufacturer:{
                    value:'',
                    caption:'Hersteller',
                    rules:[
                        v => !!v || 'Eingabe ist erforderlich',
                        v => (v && v.length <= 100) || 'max. 100 Zeichen erlaubt',
                    ],
                    counter: 100,
                },
                date_of_construction:{
                    value:'',
                    caption:'Herstellungs Datum',
                    rules:[
                        v => !!v || 'Eingabe ist erforderlich',
                        v => /^(?:(?:1[6-9]|[2-9]\d)?\d{2})(?:(?:(\/|-|\.)(?:0?[13578]|1[02])\1(?:31))|(?:(\/|-|\.)(?:0?[13-9]|1[0-2])\2(?:29|30)))$|^(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(\/|-|\.)0?2\3(?:29)$|^(?:(?:1[6-9]|[2-9]\d)?\d{2})(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:0?[1-9]|1\d|2[0-8])$/.test(v) || 'Format YYYY-mm-dd',
                    ],
                },
                token:{
                    value:'',
                    caption:'Token',
                    rules:[],
                },                
            },


            rules: {
                required: v => !!v || 'This field is required'
            }, 
            isLoading: false,
            message: '',
            errMessage: '',
            form: false,

        }
    },

    computed:{            

        user(){
            return this.$store.state.auth.user
        },

        company() {
            return this.$store.state.app.actCompany.meta
        },

        cmpOptions(){
            //console.debug('cmpOptions');
            let res = {
                ...this.defaultOptions,
                ...this.options
            
            };
            return res;
        },

        captionBtnSubmit(){
            return this.cmpOptions.isEdit ? 'Ändern' : 'Speichern';
        },

    },

    methods:{ 

       onChange(event, prop) {
            console.debug('onChange', event, prop)
            
            this.dataTimePicker = false;
            if(prop == "date_picker")
                this.data.date_of_construction.value = this.datePickerValue;
            
            if(prop == "date_of_construction" && this.$refs["date_of_construction"].valid)
                this.datePickerValue = this.data.date_of_construction.value; 
        },
        
        close(event){
            console.debug('close', event)
            this.$emit('close', event)  
            this.$emit('update:isVisible', false)        
        },

        submit(){
            console.debug('submit', this.editItem)
            for (const key in this.editItem) {
                if (Object.hasOwnProperty.call(this.data, key)) {
                    this.editItem[key] = this.data[key].value 
                }
            }
            const _this = this
            const apicmd = this.cmpOptions.isEdit ? "putApiSetStore" : "postApiSetStore"
            const data = {...this.editItem ,...{compid: this.company.id}}
            this.$store.dispatch("app/" + apicmd, {path:"api/company/smartdevice", param:{ store:"devices/smartdevices", data:data}})
            .then((response)=>{ 
                console.log("submit:", response); 
                this.$emit('close')  

            })
            .catch((error)=>{
                _this.errMessage = error
                console.error(error);
            })
    
        },

        init(){
            console.debug('init');
            this.errMessage  = '';
            
            // this.editItem = this.cmpOptions.isEdit ? JSON.parse(JSON.stringify(this.item)) : JSON.parse(JSON.stringify(this.defaultItem))

            if(this.cmpOptions.isEdit){
                this.editItem = JSON.parse(JSON.stringify(this.item))
                for (const key in this.editItem) {
                    if (Object.hasOwnProperty.call(this.data, key)) {
                        this.data[key].value = this.editItem[key]
                    }
                }
            } else
                this.$refs.form.reset();

            if(!this.$refs["date_of_construction"].valid){
                let today = new Date();
                const dd = String(today.getDate()).padStart(2, '0');
                const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                const yyyy = today.getFullYear();

                today = yyyy + '-' + mm + '-' + dd ;
                this.datePickerValue = today
            }
            else
                this.datePickerValue = this.data.date_of_construction.value;
                

        }
       

    },
    watch: { 
        isVisible(newVal) { // watch it            
            if(newVal)            
                this.init()
            
        }
    },

    mounted() {
        console.debug('mounted');
        if(this.cmpOptions.isEdit)
            this.init()
 
    }

}

</script>